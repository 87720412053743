import * as actionTypes from "./actionTypes";
import API from "../../api";
import { history } from "../../index";
import { convertFiltersToQueryParams } from "../../utils/util";
//Role
const storeRole = data => {
  return {
    type: actionTypes.STORE_ROLE,
    data
  };
};

const storeRolePermissions = (payload, page, pageSize) => {
  return {
    type: actionTypes.STORE_ROLE_PERMISSIONS,
    payload,
    page,
    pageSize
  };
};

export const fetchRole = id => {
  return dispatch => {
    API.get(`/role/${id}`).then(response => {
      dispatch(storeRole(response.data));
    });
  };
};
export const deleteRolePermission = rolePermissionId => {
  return (dispatch, geState) => {
    const { role: { permissions: { page, pageSize }, data: { id: param } } } = geState();
    API.delete(`/role_permission/${rolePermissionId}`).then(response => {
      dispatch(
        fetchRolePermissions(
          {
            page,
            pageSize,
            param
          }
        )
      );
    });
  };
};
export const fetchRolePermissions = ({ page, pageSize, param, filters }) => {
  return dispatch => {
    if (param) {
      const filteredQuery = convertFiltersToQueryParams(filters);
      API.get(
        `/role/${param}/assigned_permissions?&page=${page + 1}&limit=${pageSize}${filteredQuery}`
      ).then(response => {
        dispatch(storeRolePermissions(response.data, page, pageSize));
      });
    }
  };
};
export const assignRolePermissions = (roleId, permissionIds) => {
  return dispatch => {
    API.post(`/role_permission/bulk_create`, {
      roleId,
      permissionIds
    }).then(response => {
      history.push(`/roles/${roleId}`);
    });
  };
};
