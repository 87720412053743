import React, { useEffect } from "react";
import { Auth } from "./containers";
import Router from "./router";
import { Layout } from "./containers";
import { validateToken } from "./utils/util";
import store from "./store";
import { useSelector } from "react-redux";
import { loginSuccessful } from "./store/actions";
import withErrorHandler from "./hoc/withErrorHandler/withErrorHandler";

function App() {
  const isLoggedIn = useSelector(state => state.auth.loggedIn);

  // Initialize store from localstorage
  useEffect(() => {
    const checkValidToken = validateToken();
    if (checkValidToken.isValid) {
      store.dispatch(loginSuccessful(checkValidToken.authData));
    }
  }, []);

  return (
    <>
      {!isLoggedIn ? (
        <Auth />
      ) : (
        <Layout>
          <Router />
        </Layout>
      )}
    </>
  );
}

export default withErrorHandler(App);
