import RootReducer from "../reducers";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeConfig = () => {
  const store = createStore(
    RootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};

export default storeConfig;
