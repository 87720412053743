import * as actionTypes from "./actionTypes";

export const resetState = () => {
  return dispatch => {
    dispatch({ type: actionTypes.RESET_STATE });
  };
};
export const changePagenation = (data) => {
  return dispatch => {
    dispatch({ type: actionTypes.CHANGE_PAGINATION, data });
  };
};
