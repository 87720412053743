import * as Pages from "../../pages";
const Links = [
  { name: "Roles", path: "/roles", page: Pages.Roles, type: "main" },
  {
    name: "Permissions",
    path: "/permissions",
    page: Pages.Permissions,
    type: "main"
  },
  {
    name: "Role Details",
    path: "/roles/:id",
    page: Pages.RoleDetails,
    type: "details"
  },
  {
    name: "Assign Permissions",
    path: "/roles/:id/permissions",
    page: Pages.AssignPermission,
    type: "details"
  }
];
export default Links;
