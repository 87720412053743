import moment from "moment";
import React from "react";
import {
  FormControl,
  Input,
  FormHelperText
} from "@material-ui/core";
import { matchResource } from "../../utils/util";

export const columns = [
  { title: "Name", field: "name" },
  { title: "Description", field: "description" },
  {
    title: "Action",
    field: "action",
    filtering: false,
    lookup: {
      post: "post",
      get: "get",
      delete: "delete",
      put: "put",
      patch: "patch"
    }
  },
  {
    title: "Resource",
    field: "resource",
    editComponent: props => {
      let matchedResource = true;
      if (props.value) {
        matchedResource = matchResource(props.value);
      }
      return (
        <FormControl error={!matchedResource}>
          <Input
            placeholder="Resource"
            id="component-error"
            onChange={e => props.onChange(e.target.value)}
            aria-describedby="component-error-text"
          />
          {!matchedResource && (
            <FormHelperText id="component-error-text">
              Please follow this pattern '/user/:id/test'
            </FormHelperText>
          )}
        </FormControl>
      );
    }
  },
  {
    title: "Created At",
    field: "createdAt",
    filtering: false,
    editable: "never",
    render: data => {
      if (data) {
        const formatedDate = moment(data.createdAt).format("LLL");
        return formatedDate;
      }
    }
  },
  {
    title: "Updated At",
    field: "updatedAt",
    filtering: false,
    editable: "never",
    render: data => {
      if (data) {
        const formatedDate = moment(data.updatedAt).format("LLL");
        return formatedDate;
      }
    }
  }
];
