import React from "react";
import classes from "./UploadButton.css";
import { Icon } from "@material-ui/core";

const UploadButton = props => {
  return (
    <>
      <input
        accept=".csv"
        className={classes.hideInputFile}
        id="outlined-button-file"
        type="file"
        onChange={props.onChange}
      />
      <label htmlFor="outlined-button-file">
        <Icon>cloud_upload</Icon>
      </label>
    </>
  );
};

export default UploadButton;
