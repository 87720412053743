import moment from "moment";
import * as Sentry from "@sentry/browser";
import { isNil, isEmpty, range } from "lodash";

//to validate the token if expired or not to be used in the api calls headers
// return true or false valid token
export const validateToken = () => {
  const authData =
    localStorage.getItem("auth_data") &&
    JSON.parse(localStorage.getItem("auth_data"));
  if (!authData || !authData.token) {
    return false;
  }

  const isValid = moment(Date.now()).isSameOrBefore(
    moment(authData.expires_at)
  );
  return { isValid, authData };
};

export const getRefreshToken = () => {
  const authData = JSON.parse(localStorage.getItem("auth_data"));
  return authData && authData.refresh_token;
}
export const getSuccessResponseMessage = config => {
  if (config.method === "post") {
    return "Created Successfully";
  } else if (config.method === "delete") {
    return "Deleted Successfully";
  } else return null;
};
export const matchResource = name => {
  const match = name.match(/(\/[\w -]+)+((\/:id+)?((\/[\w -]+)+)?)+$/g);
  return match && name === match[0];
};

export const isValidForCreate = (data, columns) => {
  if (data["resource"] && !matchResource(data["resource"])) {
    return false;
  }
  const filteredData = columns.filter(column => {
    return column.editable !== "never";
  });
  for (const iterator of filteredData) {
    if (isNil(data[iterator.field]) || isEmpty(data[iterator.field].trim())) {
      return false;
    }
  }
  return true;
};

export const getPageSizeOptions = totalCounts => {
  if (totalCounts > 5) {
    const step = Math.floor(totalCounts / 4);
    let ranges = range(5, totalCounts, step - 1)
    ranges = [...ranges, totalCounts]
    return ranges;
  }
  else {
    return [1, 5];
  }
}
export const convertFiltersToQueryParams = filters => {
  if (filters && filters.length) {
    const filteredQuery = filters.reduce((prevValue, currentValue) => {
      return `${prevValue}&${currentValue.column.field}=${currentValue.value}`
    }, "")
    return filteredQuery;
  }
  else {
    return "";
  }
}
export const initializeSentry = userId => {
  window._env_.NODE_ENV &&
    Sentry.init({
      dsn: '',
      environment: window._env_.NODE_ENV,
      release: window.SENTRY_RELEASE && window.SENTRY_RELEASE.id
    });
  Sentry.configureScope(function (scope) {
    scope.setUser({ userId: userId });
  });
};
