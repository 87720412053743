import moment from "moment";

export const columns = [
  { title: "Name", field: "name" },
  {
    title: "Description",
    field: "description",
    filtering: false,
  },
  {
    title: "Created At",
    field: "createdAt",
    editable: "never",
    filtering: false,
    render: data => {
      if (data) {
        const formatedDate = moment(data.createdAt).format("LLL");
        return formatedDate;
      }
    }
  },
  {
    title: "Updated At",
    field: "updatedAt",
    filtering: false,
    editable: "never",
    render: data => {
      if (data) {
        const formatedDate = moment(data.updatedAt).format("LLL");
        return formatedDate;
      }
    }
  }
];