import React, { useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles, createRole, deleteRole } from "../../store/actions/roles";
import { useSnackbar } from "notistack";
import { columns } from "./config";
import { isValidForCreate, getPageSizeOptions } from "../../utils/util";

import usePagination from "../../utils/usePagination";

export default function RolesPage(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = useRef();

  const pagination = usePagination(fetchRoles);

  const roles = useSelector(state => state.roles);
  useEffect(() => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  }, [roles]);

  return (
    <MaterialTable
      tableRef={tableRef}
      title="Roles"
      columns={columns}
      options={{
        exportButton: false,
        actionsColumnIndex: -1,
        filtering: true,
        addRowPosition: "first",
        search: false,
        pageSizeOptions: getPageSizeOptions(roles.totalCount)
      }}
      data={onPaginationChanged()}
      onRowClick={(event, row) => {
        props.history.push({
          pathname: `/roles/${row.id}`
        });
      }}
      editable={{
        onRowAdd: addRole(),
        onRowDelete: removeRole()
      }}
    />
  );

  function onPaginationChanged() {
    return query => {
      return new Promise((resolve, reject) => {
        pagination({ page: query.page, pageSize: query.pageSize, filters: query.filters });
        resolve({
          data: roles.data,
          page: query.page,
          totalCount: roles.totalCount
        });
      });
    };
  }
  function removeRole() {
    return oldData =>
      new Promise(resolve => {
        dispatch(deleteRole(oldData.id));
        resolve();
      });
  }

  function addRole() {
    return newData => {
      return new Promise((resolve, reject) => {
        if (isValidForCreate(newData, columns)) {
          dispatch(createRole(newData));
          resolve();
        } else {
          enqueueSnackbar("There should not be any empty fields");
          reject();
        }
      });
    };
  }
}
