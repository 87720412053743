import React from "react";
import Aux from "../Aux";
import store from "../../store";
import { logoutSuccess } from "../../store/actions";
import API from "../../api";
import AUTH_API from "../../api/auth";
import { useSnackbar } from "notistack";
import { getSuccessResponseMessage } from "../../utils/util";

const withErrorHandler = Component => {
  return props => {
    const { enqueueSnackbar } = useSnackbar();
    const successCallback = (res) => {
      const message = getSuccessResponseMessage(res.config);
      message &&
        enqueueSnackbar(message, {
          autoHideDuration: 2000,
          variant: "success"
        });
      return res;
    }
    const errorCallback = (err) => {
      const errorData = err.response.data;
      let errorMessage = errorData.message ? errorData.message : (errorData.error && errorData.error.message);
      errorMessage = errorMessage || err.message;
      if (err.response && err.response.status === 401) {
        store.dispatch(logoutSuccess());
      }
      err &&
          enqueueSnackbar(errorMessage, {
            autoHideDuration: 2000,
            variant: "error"
          });
      return Promise.reject(err);
    }
    API.interceptors.response.use(successCallback,errorCallback);
    AUTH_API.interceptors.response.use(successCallback,errorCallback);
    return (
      <Aux>
        <Component {...props} />
      </Aux>
    );
  };
};

export default withErrorHandler;
