import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { get } from "lodash";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Tooltip
} from "@material-ui/core";
import moment from "moment";

const RoleDetailsHeader = props => (
    <Grid item xs={11}>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h6">Role Details</Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container justify="flex-end">
                  {props.isDisabled ? (
                    <Tooltip key={"Edit"} title={"Edit"} aria-label="Edit">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          props.setIsDisabled(!props.isDisabled);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip key={"Save"} title={"Save"} aria-label="Save">
                        <IconButton aria-label="save">
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        key={"Cancel"}
                        title={"Cancel"}
                        aria-label="Cancel"
                      >
                        <IconButton
                          aria-label="cancel"
                          color="primary"
                          onClick={() => {
                            props.setIsDisabled(!props.isDisabled);
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={3}
              style={{
                marginTop: "10px"
              }}
            >
              <Grid item xs={6}>
                <TextField
                  disabled={props.isDisabled}
                  id="outlined-disabled"
                  label="Name"
                  defaultValue={get(props.role, "name")}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.isDisabled}
                  id="outlined-disabled"
                  label="Updated At"
                  defaultValue={moment(get(props.role, "updatedAt")).format("LLL")}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.isDisabled}
                  id="outlined-disabled"
                  label="Created At"
                  defaultValue={moment(get(props.role, "createdAt")).format("LLL")}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.isDisabled}
                  id="outlined-disabled"
                  label="Description"
                  defaultValue={get(props.role, "description")}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
  
  export default RoleDetailsHeader;