import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Toolbar, Tooltip, IconButton } from "@material-ui/core";

const Header = props => {
  return (
    <AppBar position="fixed">
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Tabs value={props.value} onChange={props.handleChange}>
          {props.tabs.map(link => {
            return <Tab label={link.name} key={link.name} />;
          })}
        </Tabs>
        <Tooltip key={"Logout"} title={"Logout"} aria-label="Logout">
          <IconButton
            style={{
              color: "white"
            }}
            onClick={() => props.logout()}
          >
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
