import * as actionTypes from "./actionTypes";
import API from "../../api";
import { debounce } from "lodash";
import { convertFiltersToQueryParams } from "../../utils/util";
//Districts
const storePermissions = (payload, page, pageSize) => {
  return {
    type: actionTypes.STORE_PERMISSIONS,
    payload,
    page,
    pageSize
  };
};

const storeUnAssignedPermissions = (payload, page, pageSize) => {
  return {
    type: actionTypes.STORE_UN_ASSIGNED_PERMISSIONS,
    payload,
    page,
    pageSize
  };
};

export const createPermission = data => {
  return (dispatch, getState) => {
    const { permissions: { page, pageSize } } = getState();
    API.post(`/permission`, data).then(response => {
      dispatch(fetchPermissions({ page, pageSize }));
    });
  };
};
export const deletePermission = id => {
  return (dispatch, getState) => {
    const { permissions: { page, pageSize } } = getState();
    API.delete(`/permission/${id}`).then(response => {
      dispatch(fetchPermissions({ page, pageSize }));
    });
  };
};

export const fetchPermissions = ({ page, pageSize, filters }) => {
  return dispatch => {
    const filteredQuery = convertFiltersToQueryParams(filters);
    API.get(`/permission?page=${page + 1}&limit=${pageSize}${filteredQuery}`).then(response => {
      dispatch(storePermissions(response.data, page, pageSize));
    });
  };
};

export const fetchUnAssignedPermissions = (page, pageSize, param) => {
  return (dispatch, getState) => {
    const { permissions } = getState();
    debounce(() => {
      if (permissions.hasMore === true) {
        API.get(
          `/role/${param}/unassigned_permissions?page=${page}&limit=${pageSize}`
        ).then(response => {
          dispatch(storeUnAssignedPermissions(response.data, page, pageSize));
        });
      }
    }, 1000)();
  };
};
