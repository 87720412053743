import * as actionTypes from "./actionTypes";
import API from "../../api";
import { convertFiltersToQueryParams } from "../../utils/util";

//Districts
const storeRoles = (payload, page, pageSize) => {
  return {
    type: actionTypes.STORE_ROLES,
    payload,
    page,
    pageSize
  };
};

export const createRole = data => {
  return (dispatch, geState) => {
    const { roles: { page, pageSize } } = geState();
    API.post(`/role`, data).then(response => {
      dispatch(fetchRoles({ page, pageSize }));
    });
  };
};

export const deleteRole = id => {
  return (dispatch, geState) => {
    const { roles: { page, pageSize } } = geState();
    API.delete(`/role/${id}`).then(response => {
      dispatch(fetchRoles({ page, pageSize }));
    });
  };
};

export const fetchRoles = (requestData) => {
  return dispatch => {
    const filteredQuery = convertFiltersToQueryParams(requestData.filters)
    API.get(`/role?page=${requestData.page + 1}&limit=${requestData.pageSize}${filteredQuery}`).then(response => {
      dispatch(storeRoles(response.data, requestData.page, requestData.pageSize));
    });
  };
};
