import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Grid } from "@material-ui/core";
import useScrollbar from "../../utils/useScrollbar";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUnAssignedPermissions,
  assignRolePermissions
} from "../../store/actions";
import InfiniteScroll from "react-infinite-scroller";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import classes from "./AssignPermission.css";

export default function AssignRolePermission(props) {
  const permissions = useSelector(state => state.permissions);
  const dispatch = useDispatch();
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const [checkAll, setCheckAll] = useState(false)
  const scroll = useScrollbar(
    fetchUnAssignedPermissions,
    props.match.params.id
  );
  const handleChange = id => {
    setCheckedPermissions({
      ...checkedPermissions,
      [id]: {
        ...checkedPermissions[id],
        checked: !checkedPermissions[id].checked
      }
    });
  };
  const switchAllPermissions = (value) => {
    const permissionsChecked = permissions.data.reduce(
      (prevValue, currentValue) => {
        prevValue[currentValue.id] = {
          checked: value,
          name: currentValue.name
        };
        return prevValue;
      },
      {}
    );
    setCheckedPermissions(permissionsChecked);
  };
  const isAnyPermissionChecked = () => {
    return !!Object.keys(checkedPermissions)
      .find(permissionId => checkedPermissions[permissionId].checked === true);
  }
  const onCheckAllChange = () => {
    if (checkAll) {
      switchAllPermissions(false);
    }
    else {
      switchAllPermissions(true);
    }
    setCheckAll(!checkAll)
  }
  const onCancelClicked = () => {
    switchAllPermissions(false);
    setCheckAll(false)
  }
  const saveRolePermissions = () => {
    const filteredPermissions = Object.keys(checkedPermissions).filter(e => {
      return checkedPermissions[e].checked === true;
    });
    dispatch(assignRolePermissions(props.match.params.id, filteredPermissions));
  };
  const isActionDisabled = () => {
    return permissions.data.length === 0 || !isAnyPermissionChecked()
  }
  useEffect(() => {
    const permissionsChecked = permissions.data.reduce(
      (prevValue, currentValue) => {
        prevValue[currentValue.id] = {
          checked: false,
          name: currentValue.name
        };
        return prevValue;
      },
      {}
    );
    setCheckedPermissions(permissionsChecked);
  }, [permissions]);
  return (
    <div>
      <div className={classes.subHeader}>
        <h3>Assign Permissions</h3>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkAll}
              onChange={onCheckAllChange}
              name="checkAll"
            />
          }
          label="Check All"
        />
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={scroll}
        hasMore={permissions.hasMore}
        loader={
          <div className="loader" key="loader">
            Loading ...
          </div>
        }
      >
        <Grid container>
          {Object.keys(checkedPermissions).map((permissionId, i) => {
            return (
              <Grid item xs={3} key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedPermissions[permissionId].checked}
                      onChange={() => handleChange(permissionId)}
                      value={permissionId}
                    />
                  }
                  label={checkedPermissions[permissionId].name}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      <div className={classes.actionsContainer}>
        <Button variant="contained" color="default"
          disabled={isActionDisabled()}
          onClick={onCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isActionDisabled()}
          onClick={saveRolePermissions}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
