import RootReducer from "../reducers";

import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
const storeConfig = () => {
  const store = createStore(RootReducer, applyMiddleware(thunk));
  return store;
};

export default storeConfig;
