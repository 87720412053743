const auth = {
    sign_in: 'sign_in',
    sign_out: 'sign_out'
}
const endpoints = {
    ...auth
}



export default endpoints;