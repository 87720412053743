import * as actionTypes from "../../actions/actionTypes";

export const initialState = {
  data: null,
  permissions: {
    data: [],
    totalCount: 0,
    isLoaded: false,
    page: 0,
    pageSize: 5
  },
  isLoaded: false
};
const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_ROLE:
      return {
        ...state,
        data: action.data,
        isLoaded: true
      };
    case actionTypes.STORE_ROLE_PERMISSIONS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isLoaded: true,
          data: action.payload.data,
          totalCount: action.payload.totalCount
        }
      };
    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default roleReducer;
