import { combineReducers } from "redux";
import authReducer from "./auth/auth";
import rolesReducer from "./roles/roles";
import roleReducer from "./role/role";
import permissionsReducer from "./permissions/permissions";

const rootReducer = combineReducers({
  auth: authReducer,
  roles: rolesReducer,
  role: roleReducer,
  permissions: permissionsReducer
});

export default rootReducer;
