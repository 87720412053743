//Auth
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const UNAUTHORIZED_USER = "UNAUTHORIZED_USER";

//General
export const RESET_STATE = "RESET_STATE";

//Roles
export const STORE_ROLES = "STORE_ROLES";

export const STORE_ROLE = "STORE_ROLE";
export const STORE_ROLE_PERMISSIONS = "STORE_ROLE_PERMISSIONS";

//Permissions
export const STORE_PERMISSIONS = "STORE_PERMISSIONS";
export const STORE_UN_ASSIGNED_PERMISSIONS = "STORE_UN_ASSIGNED_PERMISSIONS";

export const CHANGE_PAGINATION = "CHANGE_PAGINATION";
