import React, { useEffect } from "react";

import { withRouter } from "react-router";
import Links from "../../router/links";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "../../store/actions";
import Header from "../../components/Header/Header";

function Layout(props) {
  const [value, setValue] = React.useState(0);
  const tabs = Links.filter(filterLink => filterLink.type === "main");
  const handleChange = (event, newValue) => {
    const path = Links[newValue].path;
    props.history.push(path);
  };

  useEffect(() => {
    const selectedLink = Links.findIndex(
      link => link.path === props.history.location.pathname
    );
    if (selectedLink >= 0) {
      setValue(selectedLink);
    }
  }, [props.history.location.pathname]);
  return (
    <div style={{ flexGrow: 1 }}>
      <Header
        logout={props.logout}
        tabs={tabs}
        value={value}
        handleChange={handleChange}
      ></Header>
      <div style={{ margin: "90px" }}>{props.children}</div>
    </div>
  );
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
