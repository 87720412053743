import axios from 'axios';
import { modifyRequestConfig } from './utils';

const instance = axios.create({
    baseURL: window._env_.REACT_APP_GANDALF_API_URL
})

instance.interceptors.request.use(config => {
    return modifyRequestConfig(config);
});

export default instance;