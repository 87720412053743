import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
function usePagination(action) {
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [param, setParam] = useState(null);
  const [filters, setFilters] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (page !== null && pageSize !== null) {
      dispatch(action({ page, pageSize, param, filters }));
    }
  }, [action, dispatch, page, pageSize, param, filters]);

  const setPagination = ({ page, pageSize, param, filters }) => {
    setPage(page);
    setPageSize(pageSize);
    setParam(param);
    setFilters(filters);
  };
  return setPagination;
}
export default usePagination;
