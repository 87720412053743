import axios from 'axios';
import { modifyRequestConfig } from './utils';
import endpoints from './endpoints';

const instance = axios.create({
    baseURL: window._env_.REACT_APP_AUTH_API_URL
})


instance.interceptors.request.use(config => {
    // Don't intercept login request
    if (config.url === endpoints.sign_in)
        return config;
        
    return modifyRequestConfig(config);
});

export default instance;