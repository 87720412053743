import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";

import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchRole,
  fetchRolePermissions,
  deleteRolePermission
} from "../../store/actions";
import { resetState } from "../../store/actions/general";
import RoleDetailsHeader from "./Header/Header";
import { columns } from "./config";
import usePagination from "../../utils/usePagination";
import { getPageSizeOptions } from "../../utils/util";

export default function RoleDetailsPage(props) {
  const role = useSelector(state => state.role.data);
  const rolePermissions = useSelector(state => state.role.permissions.data);
  const totalCount = useSelector(state => state.role.permissions.totalCount);
  const dispatch = useDispatch();
  const pagination = usePagination(fetchRolePermissions);
  const tableRef = useRef();

  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);
  useEffect(() => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  }, [rolePermissions]);

  useEffect(() => {
    if (!role || (role && role.id !== props.match.params.id)) {
      dispatch(fetchRole(props.match.params.id));
    }
  }, [dispatch, props.match.params.id, role]);

  function onPaginationChanged() {
    return query => {
      return new Promise((resolve, reject) => {
        pagination({ page: query.page, pageSize: query.pageSize, param: props.match.params.id, filters: query.filters });
        resolve({
          data: rolePermissions,
          page: query.page,
          totalCount: totalCount
        });
      });
    };
  }
  function removeRolePermission() {
    return oldData =>
      new Promise(resolve => {
        dispatch(deleteRolePermission(oldData.roles[0].RolesPermissions.id));
        resolve();
      });
  }
  return (
    role && (
      <Grid container justify="center" alignItems="center" spacing={2}>
        <RoleDetailsHeader
          role={role}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        ></RoleDetailsHeader>
        <Grid item xs={11}>
          <MaterialTable
            tableRef={tableRef}
            title="Role Permissions"
            columns={columns}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              pageSizeOptions: getPageSizeOptions(totalCount)

            }}
            data={onPaginationChanged()}
            editable={{
              onRowDelete: removeRolePermission()
            }}
            actions={[
              {
                icon: "add",
                tooltip: "Add User",
                isFreeAction: true,
                onClick: event => {
                  props.history.push({
                    pathname: `/roles/${props.match.params.id}/permissions`
                  });
                }
              }
            ]}
          />
        </Grid>
      </Grid>
    )
  );
}
