import { useState } from "react";
import { useDispatch } from "react-redux";
function useScrollbar(action,param) {
  const [pageSize] = useState(20);
  const dispatch = useDispatch();

  const setPagination = (page) => {
    dispatch(action(page, pageSize, param));
  };
  return setPagination;
}
export default useScrollbar;
