import { validateToken } from '../utils/util';
import store from '../store';
import { logout } from '../store/actions';

export const modifyRequestConfig = (config) => {
    // Add auth token to each request if exists/valid
    const checkValidToken = validateToken();
    if (checkValidToken.isValid) {
         const token = checkValidToken.authData.token;
         config.headers.Authorization = `Bearer ${token}`;
        return config;
    }
    else {
        // Else logout and don't continue the request
        store.dispatch(logout())
        return;
    }
}