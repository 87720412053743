import React, { useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPermissions,
  createPermission,
  deletePermission
} from "../../store/actions";
import { useSnackbar } from "notistack";
import { columns } from "./config";
import { isValidForCreate, getPageSizeOptions } from "../../utils/util";
import usePagination from "../../utils/usePagination";
import UploadButton from "../../components/UploadButton/UploadButton";
export default function PermissionsPage(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = useRef();
  const pagination = usePagination(fetchPermissions);

  const permissions = useSelector(state => state.permissions);

  useEffect(() => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  }, [permissions]);

  return (
    <MaterialTable
      title="Permissions"
      tableRef={tableRef}
      columns={columns}
      options={{
        exportButton: false,
        actionsColumnIndex: -1,
        addRowPosition: "first",
        filtering: true,
        search: false,
        pageSizeOptions: getPageSizeOptions(permissions.totalCount)
      }}
      data={onPaginationChanged()}
      editable={{
        onRowDelete: removePermission(),
        onRowAdd: addPermission()
      }}
      actions={[
        {
          icon: _ => {
            return <UploadButton onChange={onUploadChange} />;
          },
          onClick: () => { },
          isFreeAction: true
        }
      ]}
    />
  );
  function onUploadChange(e) {
    console.log(e.target.value);
  }

  function onPaginationChanged() {
    return query => {
      return new Promise((resolve, reject) => {
        pagination({ page: query.page, pageSize: query.pageSize , filters: query.filters});
        resolve({
          data: permissions.data,
          page: query.page,
          totalCount: permissions.totalCount
        });
      });
    };
  }

  function removePermission(data) {
    return data =>
      new Promise(resolve => {
        dispatch(deletePermission(data.id));
        resolve();
      });
  }

  function addPermission() {
    return data => {
      return new Promise((resolve, reject) => {
        if (isValidForCreate(data, columns)) {
          dispatch(createPermission(data));
          resolve();
        } else {
          enqueueSnackbar("There should not be any empty fields");
          reject();
        }
      });
    };
  }
}
