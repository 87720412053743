import * as actionTypes from "../../actions/actionTypes";

export const initialState = {
  data: [],
  isLoaded: false,
  totalCount: 0,
  page: 0,
  hasMore: true,
  pageSize: 5
};
const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_PERMISSIONS:
      return {
        ...state,
        data: action.payload.data,
        totalCount: action.payload.totalCount,
        isLoaded: true,
        page: action.page,
        pageSize: action.pageSize
      };
    case actionTypes.STORE_UN_ASSIGNED_PERMISSIONS:
      const currentState = {
        ...state,
        data: state.data.concat(action.payload.data),
        totalCount: action.payload.totalCount,
        isLoaded: true,
        page: action.page,
        pageSize: action.pageSize
      };
      if (currentState.data.length >= currentState.totalCount) {
        currentState.hasMore = false;
      }
      return currentState;
    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default permissionsReducer;
